.issue_list {
  overflow: scroll;
  width: 700px;
  height: 200px;
}

.term_list {
  overflow: scroll;
  width: 700px;
  height: 100px;
}

.form_source {
  overflow:auto;
  text-align: left;
  /* width: 700px; */
  /* height: 700px; */
}

.form_overview {
  /* overflow: scroll; */
  overflow: hidden;
  /* width: 700px; */
  height: 400px;
}

.form_id {
  overflow: auto;
  width: 50px;
  height:20px;
}

.form_subject {
  overflow: auto;
  width: 700px;
  height:20px;
}

.form_url {
  width: 700px;
  height: 20px;
}

.form_term_list {
  overflow: scroll;
  width: 200px;
  height:120px;
}

.form_yoyaku {
  overflow:auto;
  text-align: left;
  /* width: 200px; */
  /* height: 700px; */
}

.react_table_source_and_yoyaku {
  /* overflow: scroll; */
  overflow:auto;
  text-align: left;
  /* width: 200px; */
  /* height: 700px; */
}

