/*  */
ul.background {
	padding			:	1px;
	color			:	#000000;
	background		:	#FFFFE1;
}

/*  */
div.header {
	color			:	#000000;
	background		:	#FFFFE1;
	text-align		:	center;
}

/*  */
div.footer {
	color			:	#000000;
	background		:	#FFFFE1;
	text-align		:	center;
}

/*  */
tr.row {
	text-align		:	left;
/*
	border-style	:	solid;
	border-color	:	#CCCCFF;
*/
	border-width	:	1px;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#FFFFE1;
}

tr.row_lightBlue1 {
	text-align		:	left;
	border-style	:	solid;
	border-width	:	1px;
	border-color	:	#CCCCFF;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#ccccff;
}
tr.row_lightBlue2 {
	text-align		:	left;
	border-style	:	solid;
	border-width	:	1px;
	border-color	:	#CCCCFF;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#F0F0FF;
}

/*  */
tr.row_selected {
	text-align		:	left;
	border-style	:	solid;
	border-width	:	1px;
	border-color	:	#CCCCFF;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#FFEEEE;
}

/*  */
tr.row_no_border {
	text-align		:	left;
	border-style	:	solid;
	border-width	:	0px;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#FFFFE1;
}

/*  */
tr.row_no_border_subject {
	text-align		:	left;
	border-style	:	solid;
	border-width	:	0px;
	vertical-align	:	middle;
	color			:	#000000;
	background		:	#CCDD66;
}

/*  */
ul.issue_list {
	background		:	#FFFFE1;
}

div.issue_list {
	background		:	#FFFFE1;
	font-size		:	15px;
	padding			: 10px 10px 10px 10px;	/* t r b l */
}

div.issue_list_header {
	background		:	 #202020;	/* 背景：黒 */
	font-size		:	15px;
	text-align		:	center;
	padding			:	2px;
}

div.issue_list_pager {
	background		:	 #FFFFE1;
	font-size		:	x-large;
	text-align		:	right;
	float			:	right;
}

/*  */
div.id {
	width			:	30px;
}

/*  */
tr.background_purple {
	background		:	#CCCCFF;
}


/*  */
td.width_subject {
	width			:	300px;
}

/*  */
td.tag {
	width			:	220px;
}

/*  */
td.hasBorder {
	border-width	:	1px;
	border-color	:	#CCCCFF;
}

/*  */
th.tag {
	width			:	220px;
}

/*  */
td.accessRanking_count {
	width			:	50px;
}

/*  
	width			:	250px;
*/
td.accessRanking_subject {
}

/*  */
td.subject_width_fix {
	width			:	300px;
}

/*  */
td.date {
	width			:	150px;
	text-align		:	center;
}

/*  */
td.text_right {
	text-align		:	right;
}

span.header_widht_subject {
	font-size		:10px; 
}

.background_ranking {
	background	:#EE82EE;	/* pink系 */
}

/* only_amazon */
.only_amazon {
	float		:left;
	background	:#CCDD66;
	margin		:1px;
	padding		:1px;
}

span.amazon_view_background {
	font-weight		:bold;
	background:none repeat scroll 0 0 #CCDD66;
}

td.amazon_view_background {
	background		:	#CCDD66;
}

span.small_font {
	font-size		:9px;
}

h1.banner {
	padding			: 10px 10px 10px 10px;	/* t r b l */
	font-size		:19px;
	text-align		:left;
	color			:#FFFFFF;
	font-weight		:bold;
	background      : #202020;	/* 背景：黒 */
	margin			: 0px;
}

div.banner {
	font-size		:19px;
	text-align		:left;
	color			:#FFFFFF;
	font-weight		:bold;
}

span.banner {
	font-size		:19px;
	text-align		:left;
	color			:#FFFFFF;
	font-weight		:bold;
}

div.bannerProd {
	font-size		:19px;
	text-align		:left;
	color			:#FFFFFF;
	font-weight		:bold;
}

div.fontWhite {
	color			:#FFFFFF;
}

/* 赤色 */
span.color_red_price {
	color			:	#FF7777;
}

span.text_center {
	text-align		:	center;
}

span.text_color_glay {
	color: #999999;
}

/*  */
td.prodDetailHeader {
	width			:	70px;
	text-align		:	left;
}

/*  */
td.prodGiftSetHeader {
	width			:	170px;
	text-align		:	center;
}

/*  */
td.subjectProd {
	width			:	200px;
}

h1.prod {
	border-bottom	: 1px dotted #999999;
	border-top		: 1px solid #202020;
	color			: #003366;
	font-size		: 18px;
	font-weight		: normal;
	line-height		: 26px;
	margin			: 0 0 20px;
	padding			: 3px 0;
	text-align		: left;
}

h1.issueDetailSubject {
	border-bottom	: 1px dotted #999999;
	border-top		: 1px solid #202020;
	color			: #003366;
	font-size		: 24px;
	font-weight		: normal;
	line-height		: 26px;
	margin			: 0 0 20px;
	padding			: 3px 0;
	text-align		: left;
	text-indent		: 0px;	/* ※ */
}

h1.issue {
	border-bottom	: 1px dotted #999999;
	border-top		: 1px solid #202020;
	color			: #003366;
	font-size		: 18px;
	font-weight		: normal;
	line-height		: 26px;
	margin			: 0 0 20px;
	padding			: 3px 0;
	text-align		: left;
	text-indent		: 0px;	/* ※ */
}

h3.prod {
	border-right	: 0px;
	border-top		: 1px dotted #999999;
	border-bottom	: 0px;
	font-size		: 70%;
	border-left		: 0px;
	text-indent		: 0px;
}

/* ﾘﾝｸ banner 背景色ﾀﾞｰｸ対応 */
A:link.subjectFooter	{text-decoration: none;	color: #0000CC;}
A:visited.subjectFooter	{ color: #0000CC; }
A:hover.subjectFooter	{ color: #0000CC; }
A:active.subjectFooter	{ color: #0000CC; }

A:link.red		{text-decoration: none;	color: #9C0204;}
A:visited.red	{text-decoration: none;	color: #9C0204;}
A:hover.red		{ color: #9C0204; }
A:active.red	{ color: #9C0204; }

A:link.black	{text-decoration: none;	color: #202020;}
A:visited.black	{text-decoration: none;	color: #202020;}
A:hover.black	{ color: #202020; }
A:active.black	{ color: #202020; }

.a, cite, cite a:link, cite a:visited, .cite, .cite:link, #mbEnd cite b, #tads cite b, #tadsb cite b, #tadsto cite b, #ans > i, .bc a:link {
    color: #009933;
    font-style: normal;
}

td.prodFooter {
	background: #202020;	/* 背景：黒 */
}
div.prodFooter {
	background: #202020;	/* 背景：黒 */
}
td.prodCartButton {
	background: #202020;	/* 背景：黒 */
}
div.prodCartButton {
	background: #202020;	/* 背景：黒 */
}

div.SENTENCE_DIV {
	color			: #00008B;	/* 濃紫 */
	margin			: 0px 0px 20px 0px;	/* t r b l */
}

div.SELECTED_OVERVIEW {
/*	font-weight		: bold; */
/*	color			: #cc0033;	 赤 */
/*	font-size       : 120%; */
	background      : white;
}

div.OVERVIEW_HEADER_DIV {
/*	color			: #4169E1;	 濃紫 */
/*	color			: #00008B;	 濃紫 */
	color			: #003366;	/* 濃紫 */
	margin			: 0px 0px 7px 0px;	/* t r b l */
	font-weight		: bold;
/*	font-size		: 120%;	*/
    letter-spacing  : 0.1em;
}

.OVERVIEW_HEADER_DIV_CONTENTS {
	display: table-cell;
}

.sp_overview_font {
/*     margin          :0px 20px 0px 20px; *//* ｽﾏﾎ用に左右ﾏｰｼﾞﾝをいれる。特にiphone5のような小さい画面に必要 */
    margin          :0px 0px 0px 0px;
    font-size       :18px;
    letter-spacing  :0.2em;
}
.pc_overview_font {
/*     margin          :0px 20px 0px 20px; */
    margin          :0px 0px 0px 0px;
    font-size       :18px;
    letter-spacing  :0.2em;
}

span.SENTENCE_FIRST {
/*	color			: #4B4BDE;	/* 濃紫 */
/*	color			: #1F1F78;	/* 濃紫（強） */
	color			: #00008B;
/*	font-weight		: bold; */
}
span.SENTENCE_DURING {
	color			: #4169E1;	/* 薄紫 */
/*	color			: #C3C3F6;	/* 薄紫（中） */
/*	color			: #EBEBFC;	/* 薄紫（強） */
	font-weight		: lighter;
}
span.SENTENCE_LAST {
/*	color			: #4B4BDE;	/* 濃紫 */
/*	color			: #1F1F78;	/* 濃紫（強） */
	color			: #00008B;
/*	font-weight		: bold;*/
}
span.WITHIN_ONE_SENTENCE_FRONT {
	color			: #00008B;	/* 濃紫
/*	color			: #1F1F78;	/* 濃紫（強） */
}
span.WITHIN_ONE_SENTENCE_CENTER {

	color			: #00008B;	/* 濃紫 */
/*	color			: #4B4BDE;	薄紫 */
/*	color			: #4169E1;	薄紫 */
/*	color			: #C3C3F6;	/* 薄紫（中） */
/*	color			: #EBEBFC;	/* 薄紫（強） */
	font-weight		: lighter;
}
span.WITHIN_ONE_SENTENCE_BACK {
	color			: #00008B;	/* 濃紫 */
/*	color			: #1F1F78;	/* 濃紫（強） */
}

.BUNSETSU {
	padding-top		:5px;
	padding-bottom	:5px;
	padding-left	:20px;
/*	width:600px; */
    letter-spacing  :0.2em;
}

span.COLOR_HILIGHT0 {
	color			: #66cc00;
}
span.COLOR_HILIGHT1 {
	color			: #cc0033;
}
span.COLOR_HILIGHT2 {
	color			: #cc33ff;
}

.SENTENCE_CONTAINER {
	overflow: hidden;
}

/* clearfix */
.SENTENCE_CONTAINER:before,
.SENTENCE_CONTAINER:after {
    content: "";
    display: table;
}
 
.SENTENCE_CONTAINER:after {
    clear: both;
}
 
/* For IE 6/7 (trigger hasLayout) */
.SENTENCE_CONTAINER {
    zoom: 1;
    margin-bottom: 17px;

}

.SENTENCE_FLOAT_BOX {
	float: left;
}

.SENTENCE_CATEGORY {
	background		:	white;
	border-radius			: 10px;	/* CSS3草案 */  
	-webkit-border-radius	: 10px;	/* Safari,Google Chrome用 */  
	-moz-border-radius		: 10px;	/* Firefox用 */
	padding					: 2px 13px 2px 0px;
	margin					: 10px 0px 10px 0px;
	display: inline-block;
	text-align:left;
	width:70px;
    font-size       :11px;
	letter-spacing:0.1em;
	display:none;
}

.SENTENCE_INDEX {
    display:none;
}

/* yellow */
span.backgroundYellow {
	background		:	#FFFFE1;
	background:none repeat scroll 0 0 #FFFFE1;
}

li.backgroundYellow{
	background		:	#FFFFE1;
	background:none repeat scroll 0 0 #FFFFE1;
}

/* yellow */
div.backgroundYellow {
	background		:	#FFFFE1;
}

/*  */
textarea.standard {
	color			:	#000000;
	background		:	#FFFFE1;
}

/* メニュー用の大文字 */
a.menu {
	font-size		: 18px;
}

/* メニュー用の中文字 */
a.menu_medium {
	font-size		: 14px;
}

/* 角丸 */
.radius {
	border-radius			: 10px;	/* CSS3草案 */  
	-webkit-border-radius	: 10px;	/* Safari,Google Chrome用 */  
	-moz-border-radius		: 10px;	/* Firefox用 */
	padding					: 4px;
	margin					: 2px;
}

/* TOPのみ角丸 */
.radius_top {
    -webkit-border-top-left-radius      : 5px;  
    -webkit-border-top-right-radius     : 5px;  
    -moz-border-radius-topleft          : 5px;  
    -moz-border-radius-topright         : 5px;  
	padding					: 4px;
}

/* 枠 */
.border {
    border-style:solid;
    border-color:#EE82EE;	/* pink系 */
    border-width:1px;
}

.subject {
/*	border-left		: 6px solid #004400;	 濃緑系 */
/*	line-height		: 130%;*/
/*	margin-top		: 6px !important; */
/*	margin-bottom	: 6px !important; */
/*	padding-left	: 6px;*/

/*	font-size	: x-large; */
	font-weight	: bold;
	background	: #FFC7AF;	/* orange系 */
}

span.prodSubject {
	font-size		:19px;
	text-align		:left;
	color			:#FFFFFF;
	font-weight		:bold;
}

.subject_background {
	background	:#232850;	/* 濃青 */
}

span.subject_tab_background_active {
	float		:left;
	background	:#EE82EE;	/* 濃青 */
	margin		:2px;
	padding		:4px;
}

span.subject_tab_background_non_active {
	float		:left;
	background	:#CCDD66;	/* ｸﾞﾘｰﾝ系 */
	margin		:2px;
	padding		:4px;
}

/* SUBJECT：ﾀｸﾞ */
.subject_tag {
	float		:left;
	background	:#FFFF00;	/* 濃黄系 */
	margin		:2px;
	padding		:4px;
}

.subject_tag_left {
	float		:left;
	background	:#FFFF00;	/* 濃黄系 */
}

.subject_tag_right {
	float		:right;
	margin		:2px;
	padding		:4px;
}

/* SUBJECT：ｷｰﾜｰﾄﾞ */
.subject_tag_keyword_background {
	background	:#CCDD66;	/* ｸﾞﾘｰﾝ系 */
}

/* SUBJECT：関連記事 */
.subject_tag_related_background {
	background	:#00FFFF;	/* 濃水色系 */
}

/* SUBJECT：ﾀｸﾞ説明 */
.subject_tag_explain {
	float:left;
	color:black;
	font-size: small;
	margin-left:10px;
	margin-top:8px;
	background:#CCCCFF;	/* 灰青系 */
	padding:4px; 
}

div.H1_wiki {
	font-size: 22px;
	margin: 0px;
	border-top-style: none;
	border-bottom	: none;
	color:#3d7097;
	text-align:center;
	font-weight		:bold;
}

div.H2_wiki {
	font-size: 18px;
/*	text-indent: 20px; */
	padding-left	:20px;
	padding-top		:5px;
	padding-bottom	:5px;
	margin: 0px;
	border-top-style: none;
	border-bottom-style: none;
	color:#3d7097;
}

div.H3_wiki {
	font-size: 18px;
/*	text-indent: 40px; */
	padding-left	:40px;
	padding-top		:5px;
	padding-bottom	:5px;
	margin: 0px;
	border-top-style: none;
	border-bottom-style: none;
	color:#3d7097;
}

h2.issue_list {
	font-size: 18px;
	text-indent: 5px;
	margin: 0px;
	border-top-style: none;
	border-bottom-style: none;
	color:#3d7097;
}

h2.issue_detail {
	font-size: medium;
	text-indent: 5px;
	margin: 0px;
	padding			: 10px 10px 10px 10px;	/* t r b l */
	border-top-style: none;
	border-bottom-style: none;
	color:#3d7097;
	background	: #FFC7AF;	/* orange系 */
}

h2.issue_detail_index {
	border-bottom	: 1px dotted #999999;
	border-top		: 1px solid #202020;
	color			: #003366;
	font-size		: 18px;
	font-weight		: bold;
	line-height		: 26px;
	margin			: 10px 0px 10px 0px;	/* t r b l */
	padding			: 3px 0 3px 7px;
	text-align		: left;
	text-indent		: 0px;	/* ※ */
	background	    : #FFC7AF;	/* orange系 */
}

h2.issue_subject_sub {
	font-size: medium;
	text-indent: 5px;
	margin: 0px;
	border-top-style: none;
	padding-top		: 0px;
	border-bottom-style: none;
}

div.detail_list {
	float			: left;
	padding-bottom	: 32768px;
	margin-bottom	: -32768px;
}

.center_box1 {
	text-align	: center;
	width		: 100%;
}
.center_box2 {
	text-align	: left;
	margin		: 0px auto;
	width		: 150px;
}

.background_orange {
	background	: #FFC7AF;	/* orange系 */
}
.sp_margin {
    margin:0px 15px 0px 15px;
}
